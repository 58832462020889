import React from "react";
import { connect } from "react-redux";
import MenuSidebar from "./MenuSidebar";
import Avatar from "./Avatar";
import Footer from "./Footer";

export const SidebarClosed = (props) => {
  return (
    <div
      className="fadeIn"
      style={{
        height: "93%",
        overflowY: "auto",
        overflowX: "hidden",
        paddingLeft: "38%",
      }}
    >
      {/* if width_sidebar_when_closed is 0 dont load anything*/}
      {props.layout.width_sidebar_when_closed > 0 ? (
        <React.Fragment>
          {/* Avatar picture */}
          <Avatar></Avatar>
          {/* primary menus*/}
          <div style={{ marginTop: 12 }}>
            {props.layout.menus_primary.map((obj, index) => {
              return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
            })}
          </div>
          {props.layout.menus_secondary !== undefined &&
          props.layout.menus_secondary.length > 0 ? (
            <div>
              <hr
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "65%",
                  borderTop: "1px solid gray",
                }}
              ></hr>
              {/* secondary menus*/}
              <div style={{ padding: "15px 0px" }}>
                {props.layout.menus_secondary.map((obj, index) => {
                  return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
                })}
              </div>
            </div>
          ) : null}

          <Footer></Footer>
        </React.Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps)(SidebarClosed);
