import React from "react";
import LogoGDP from "../../../images/logo_gdp.png";

export const Footer = () => {
  return (
    <div
      style={{
        bottom: 0,
        position: "absolute",
        padding: "20px 22px",
        width: "100%",
      }}
    >
      <img src={LogoGDP} style={{ width: "10%", float: "left" }}></img>
      <div style={{ marginTop: 6, marginLeft: 30, fontSize: 13 }}>
        Boosted by{" "}
        <a
          href="https://www.gdpanalytics.com"
          target="_blank"
          style={{ color: "#1F2D3D", textDecoration: "none" }}
        >
          GDP Analytics
        </a>
      </div>
    </div>
  );
};

export default Footer;
