import React, { useEffect, lazy } from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import store from "./store";
import ViewPortDimensions from "./Website/components/ViewPortDimensions/ViewPortDimensions";
import "./App.css";
import ScrollToTop from "./Website/components/ScrollToTop";
import TermsOfService from "./Website/routes/TermsOfService";
import CookiePolicy from "./Website/routes/CookiePolicy";
import Dashboard from "./Dashboard/layout";
import PrivateRoute from "./Dashboard/layout/PrivateRoute";
import Loading from "./Dashboard/layout/Loading";
import { loadUser } from "./Dashboard/actions/auth";
import ModalsListener from "./Dashboard/components/ModalsListener/ModalsListener";

const hist = createBrowserHistory();

// Homepage
const Homepage = lazy(() => import("./Website/routes/Homepage/Homepage"));
const RedirectComponent = lazy(() =>
  import("./Website/routes/Redirect/Redirect")
);
const Login = lazy(() => import("./Website/routes/Login/Login"));

// Services
const ServiceOne = lazy(() => import("./Website/routes/Services/ServiceOne"));
const ServiceTwo = lazy(() => import("./Website/routes/Services/ServiceTwo"));
const ServiceThree = lazy(() =>
  import("./Website/routes/Services/ServiceThree")
);
const ServiceFour = lazy(() => import("./Website/routes/Services/ServiceFour"));
const ServiceFive = lazy(() => import("./Website/routes/Services/ServiceFive"));

// Teams
const TeamOne = lazy(() => import("./Website/routes/Teams/TeamOne"));

// Partnership
const Partnership = lazy(() =>
  import("./Website/routes/Partnership/Partnership")
);

// Offices
const OfficeOne = lazy(() => import("./Website/routes/Offices/OfficeOne"));
const OfficeTwo = lazy(() => import("./Website/routes/Offices/OfficeTwo"));

//News
const News = lazy(() => import("./Website/routes/News/News"));
const DettaglioNews = lazy(() => import("./Website/routes/News/DettaglioNews"));

export const App = () => {
  useEffect(() => {
    store.dispatch(loadUser(true));
    // document.title has been moved in index.js in layout
  }, []);
  var urlInitial = window.location.href;

  return (
    <Provider store={store}>
      <ViewPortDimensions></ViewPortDimensions>
      <Router history={hist}>
        <div className="App">
          <ModalsListener></ModalsListener>
          <ScrollToTop />
          <Switch>
            {/* <Redirect from="/" to="/homepage" exact /> */}
            <React.Suspense fallback={<Loading></Loading>}>
              <Route
                exact
                path="/"
                render={(props) => (
                  <RedirectComponent
                    urlInitial={urlInitial}
                  ></RedirectComponent>
                )}
              // component={RedirectComponent}
              ></Route>
              <Route exact path="/homepage" component={Homepage}></Route>

              <Route
                exact
                path="/account_service"
                component={ServiceOne}
              ></Route>
              <Route
                exact
                path="/payroll_service"
                component={ServiceTwo}
              ></Route>
              <Route exact path="/ai_service" component={ServiceThree}></Route>
              <Route
                exact
                path="/legal_corporate"
                component={ServiceFour}
              ></Route>
              <Route exact path="/audit" component={ServiceFive}></Route>
              <Route exact path="/teams" component={TeamOne}></Route>
              <Route exact path="/partnership" component={Partnership}></Route>
              <Route exact path="/torino_office" component={OfficeOne}></Route>
              <Route exact path="/pescara_office" component={OfficeTwo}></Route>
              <Route
                exact
                path="/privacy_policy"
                component={TermsOfService}
              ></Route>
              <Route
                exact
                path="/cookie_policy"
                component={CookiePolicy}
              ></Route>
              <Route exact path="/news" component={News}></Route>
              <Route path="/dettaglio_news" component={DettaglioNews}></Route>
              <Route exact path="/login" component={Login}></Route>
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
              ></PrivateRoute>
            </React.Suspense>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
