import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import LogoAndBadge from "./LogoAndBadge";
import Avatar from "./Avatar";
import DropdownAndJobTitle from "./DropdownAndJobTitle";
import MenuSidebar from "./MenuSidebar";
import Footer from "./Footer";

export const SidebarOpened = (props) => {
  return (
    <div
      className={props.layout.n_toggled_sidebar > 1 ? "fadeIn" : null}
      style={{ height: "100%", color: "#8493a5" }}
    >
      {/* logo + app badge */}
      <LogoAndBadge></LogoAndBadge>

      {/* from pic to the end */}
      <div
        style={{
          height: "86%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* Avatar picture */}
        <Avatar></Avatar>
        {/* dropdown with account / admin panel and job title */}
        <DropdownAndJobTitle></DropdownAndJobTitle>

        {/* primary menus*/}
        <div style={{ padding: "29px 0px" }}>
          {props.layout.menus_primary.map((obj, index) => {
            return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
          })}
        </div>
        {props.layout.menus_secondary !== undefined &&
        props.layout.menus_secondary.length > 0 ? (
          <div>
            <hr
              style={{
                margin: "-15px 28px",
                borderTop: "1px solid gray",
              }}
            ></hr>
            {/* secondary menus*/}
            <div style={{ padding: "39px 0px" }}>
              {props.layout.menus_secondary.map((obj, index) => {
                return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Footer></Footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps)(SidebarOpened);
