import {
  OPEN_SIDEBAR,
  OPEN_SIDEBAR_WITHOUT_CLICK,
  CLOSE_SIDEBAR,
  HIDE_SIDEBAR,
  UPDATE_LAYOUT,
} from "../actions/types";
import { cloneDeep } from "lodash";
import { menusPrimarySidebar } from "../utils/menusPrimarySidebar";
import { menusSecondarySidebar } from "../utils/menusSecondarySidebar";

const initialState = {
  language: navigator.language || navigator.userLanguage,
  is_opened_sidebar: true,
  n_toggled_sidebar: 0,
  width_sidebar: 250,
  height_navbar: 56,
  width_sidebar_when_closed: 60, // used as a reference, the value doesn't change
  transition_speed: "0.8s", // speed of transition for Sibar, Navbar and Content
  sidebar_threshold_width: 800, // threshold at which should be opened/closed the sidebar on window change
  mobile_layout_width: 600,
  padding_content_from_navbar: 10,
  margin_content_from_sidebar: 25,
  app_type: "editor",
  app_type_long: "Dashboard",
  driving_color: "#4DC3FA",
  menus_primary: menusPrimarySidebar,
  menus_secondary: menusSecondarySidebar,
  active_menu: "homepage",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        width_sidebar:
          state.width_sidebar === 0 ? 0 : initialState.width_sidebar,
        is_opened_sidebar: true,
        n_toggled_sidebar: state.n_toggled_sidebar + 1,
      };
    case OPEN_SIDEBAR_WITHOUT_CLICK:
      return {
        ...state,
        width_sidebar: initialState.width_sidebar,
        is_opened_sidebar: true,
        n_toggled_sidebar: state.n_toggled_sidebar + 1,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        width_sidebar: cloneDeep(state.width_sidebar_when_closed),
        is_opened_sidebar: false,
        n_toggled_sidebar: state.n_toggled_sidebar + 1,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        width_sidebar: 0,
        is_opened_sidebar: false,
        n_toggled_sidebar: state.n_toggled_sidebar + 1,
      };
    case UPDATE_LAYOUT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
}
