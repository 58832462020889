import React from "react";
import LogoGDP from "../../../images/logo_gdp.png";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

export const Footer = (props) => {
  const location = useLocation();
  return (
    <div
      style={{
        bottom: 0,
        position: "absolute",
        margin: "20px 20px 15px -3px",
        width: "20px",
        display:
          props.layout.width_sidebar === 0 ||
          !location.pathname.includes("/dashboard")
            ? "none"
            : "",
      }}
    >
      <img src={LogoGDP} style={{ width: "100%", float: "left" }}></img>
    </div>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Footer);
