import axios from "axios";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  LOGIN_FAIL,
  API_URL,
  IS_LOADING,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";
import { getErrors } from "../utils/getErrors";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  try {
    // check if the token is valid and also user info
    const res = await axios.get(`${API_URL}/auth/user/`, tokenConfig());
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(`${API_URL}/auth/login/`, body, config);
    localStorage.setItem("token", res.data);
    dispatch(loadUser(true));
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    getErrors(err, dispatch);
  }
};

// LOGOUT
export const logout =
  (reload = true) =>
  async (dispatch) => {
    try {
      await axios.post(`${API_URL}/auth/logout/`, null, tokenConfig());
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      if (reload === true) {
        window.location.reload();
      }
    } catch (err) {
      getErrors(err, dispatch);
    }
  };
