import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import { checkLowestKey } from "../../utils/checkLowestKey";
import { isPlainObject } from "../../utils/isPlainObject";

export class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    if (errors !== prevProps.errors) {
      this.setState({ showModal: true });
    }
  }

  render() {
    const { errors } = this.props;
    return (
      <React.Fragment>
        <SweetAlert
          danger
          title="Errore"
          show={this.state.showModal}
          style={{
            width:
              Object.keys(errors.msg).length > 0 &&
              Object.keys(errors.msg).includes("values") &&
              Object.keys(errors.msg).includes("detail")
                ? 800
                : 500,
          }}
          onConfirm={() => this.setState({ showModal: false })}
          customButtons={
            <React.Fragment>
              <Button
                color="danger"
                style={{ marginLeft: 2 }}
                onClick={() => this.setState({ showModal: false })}
              >
                Chiudi
              </Button>
            </React.Fragment>
          }
        >
          {Object.keys(errors.msg).includes("detail")
            ? errors.msg.detail
            : Object.keys(errors.msg).includes("id")
            ? errors.msg.id
            : isPlainObject(errors.msg)
            ? checkLowestKey(errors.msg)
            : null}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps)(Alert);
