import React, { useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";
import Content from "./Content/Content";
import { useWindowDimensions } from "../utils/useWindowDimensions";
import { connect } from "react-redux";
import {
  openSidebarWithoutClick,
  closeSidebar,
  hideSidebar,
} from "../actions/layout";
import { initialCalls } from "../actions/initialCalls";
import { getNews } from "../actions/news";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UPDATE_LAYOUT } from "../actions/types";

export const Dashboard = (props) => {
  const { height, width } = useWindowDimensions();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Test app`;
    if (props.auth.isAuthenticated) {
      // do here all initial calls --> pass functions without ()
      props.initialCalls(props.getNews, props.getMaps);
    }
  }, []);

  useEffect(() => {
    var active_menu = location.pathname
      .split("/dashboard/")
      .slice(1, location.pathname.split("/dashboard/").length)[0];
    dispatch({
      type: UPDATE_LAYOUT,
      payload: { key: "active_menu", value: active_menu },
    });
  }, [location, props.auth]);

  // if window dimension changes, check if to close or open sidebar
  useEffect(() => {
    if (width < props.layout.mobile_layout_width) {
      props.hideSidebar();
    } else if (width < props.layout.sidebar_threshold_width) {
      props.closeSidebar();
    } else {
      props.openSidebarWithoutClick();
    }
  }, [height, width]);

  return (
    // <React.Suspense fallback="Loading....">
    <div>
      {/* left sidebar */}
      <Sidebar></Sidebar>
      {/* top navbar */}
      <Navbar></Navbar>
      {/* main content */}
      <Content></Content>
    </div>
    // </React.Suspense>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, {
  openSidebarWithoutClick,
  closeSidebar,
  hideSidebar,
  initialCalls,
  getNews,
})(Dashboard);
