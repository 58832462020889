// Setup config with token - helper function
export const tokenConfig = () => {
  // get token from state
  const token = localStorage.getItem("token");
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // NOTE: in this wrapper, there are multiple token! So there are no requests with Authorization = token
  // on logout, send all!

  // // If token, add to headers config
  // if (token) {
  //   config.headers["Authorization"] = `Token ${token}`;
  // }
  return config;
};
