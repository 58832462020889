import { GET_ERRORS, IS_LOADING } from "./types";
import { getErrors } from "../utils/getErrors";

export const initialCalls = (getNews) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    getNews();
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
