export const getLanguage = (language) => {
  const available_languages = [
    "ar",
    "zh",
    "en",
    "nl",
    "fr",
    "de",
    "it",
    "ja",
    "pt",
    "ru",
    "es",
    "uk",
  ];
  var code = language.split("-")[0];
  if (!available_languages.includes(code)) {
    code = "en";
  }
  return code;
};
