import axios from "axios";
import { getLanguage } from "../../Website/utils/getLanguage";
import { getErrors } from "../utils/getErrors";
import { tokenConfig } from "../utils/tokenConfig";

import {
  API_URL,
  IS_LOADING,
  GET_NEWS_DETAILS,
  GET_NEWS,
  CREA_NEWS,
  MODIFICA_NEWS,
  DELETE_NEWS,
} from "./types";

export const getNews = (language) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    var request;
    var languageLocal =
      language === undefined
        ? undefined
        : language.includes("-")
        ? language.split("-")[0]
        : language;
    if (languageLocal === undefined) {
      request = await axios.get(`${API_URL}/news/`, tokenConfig());
    } else {
      request = await axios.get(`${API_URL}/news/`, {
        params: {
          to_code: getLanguage(languageLocal),
        },
      });
    }
    dispatch({
      type: GET_NEWS,
      payload: request.data,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    getErrors(err, dispatch);
  }
};

export const getNewsDetails = (idNews, language) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    var request;
    var languageLocal =
      language === undefined
        ? undefined
        : language.includes("-")
        ? language.split("-")[0]
        : language;
    if (languageLocal === undefined) {
      request = await axios.get(`${API_URL}/news/${idNews}/`, tokenConfig());
    } else {
      request = await axios.get(`${API_URL}/news/${idNews}/`, {
        params: {
          to_code: getLanguage(languageLocal),
        },
      });
    }

    dispatch({
      type: GET_NEWS_DETAILS,
      payload: request.data,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const creaNews = (body) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.post(`${API_URL}/news/`, body, tokenConfig());

    dispatch({
      type: CREA_NEWS,
      payload: request.data,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const modificaNews = (id, body) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.put(
      `${API_URL}/news/${id}/`,
      body,
      tokenConfig()
    );

    dispatch({
      type: MODIFICA_NEWS,
      payload: { id, data: request.data },
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const deleteNews = (id) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.delete(`${API_URL}/news/${id}/`, tokenConfig());

    dispatch({
      type: DELETE_NEWS,
      payload: id,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const deleteSingoloFile = (body) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.post(
      `${API_URL}/news/files/delete/`,
      body,
      tokenConfig()
    );

    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
