import React from "react";
// import LogoSidebarTop from "../../../images/Logo_Ferrero.png";
import LogoSidebarTop from "../../../images/scritta_gdp.webp";
import { connect } from "react-redux";

export const LogoAndBadge = (props) => {
  return (
    <div style={{ height: 80, padding: "15px 24px" }}>
      <div style={{ fontSize: 20, fontWeight: "bold", color: "#35465A" }}>
        Welcome
      </div>
      {/* <img
        src={LogoSidebarTop}
        style={{ width: "100%", marginBottom: "3px" }}
      ></img> */}
      <div
        style={{
          background: props.layout.driving_color,
          display: "inline-block",
          padding: "1px 5px",
          fontSize: 12,
          color: "white",
          borderRadius: 4,
        }}
      >
        {props.layout.app_type_long}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps)(LogoAndBadge);
