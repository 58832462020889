import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Editor from "../../routes/Editor/Editor";
import Homepage from "../../routes/Homepage/Homepage";
import Mappe from "../../routes/Mappe/Mappe";

export const Content = (props) => {
  return (
    <div
      style={{
        paddingTop:
          props.layout.height_navbar + props.layout.padding_content_from_navbar,
        marginLeft:
          props.layout.width_sidebar + props.layout.margin_content_from_sidebar,
        marginRight: props.layout.margin_content_from_sidebar,
        transition: props.layout.transition_speed,
      }}
    >
      <Route
        path={`/dashboard/homepage`}
        render={(props) => (
          <div className="loadPageEffect">
            <Homepage {...props}></Homepage>
          </div>
        )}
      ></Route>
      <Route
        path={`/dashboard/editor`}
        render={(props) => (
          <div className="loadPageEffect">
            <Editor {...props}></Editor>
          </div>
        )}
      ></Route>
      <Route
        path={`/dashboard/mappe`}
        render={(props) => (
          <div className="loadPageEffect">
            <Mappe {...props}></Mappe>
          </div>
        )}
      ></Route>
    </div>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Content);
