import axios from "axios";
import { getErrors } from "../utils/getErrors";
import { tokenConfig } from "../utils/tokenConfig";

import { API_URL, IS_LOADING, GET_MAPPE } from "./types";

export const getMappe = () => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.get(
      `${API_URL}/mappe/`
      // tokenConfig()
    );
    dispatch({
      type: GET_MAPPE,
      payload: request.data,
    });
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const createMappe = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const request = await axios.post(
      `${API_URL}/mappe/`,
      payload,
      tokenConfig()
    );
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const deleteMappe = (id) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });

    const request = await axios.delete(`${API_URL}/mappe/${id}`, tokenConfig());
    dispatch({
      type: IS_LOADING,
    });
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
