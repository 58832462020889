import React, { useState, useEffect } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { iconsMapped } from "../../../utils/iconsMapped";
import { connect } from "react-redux";

export const RowMenuSidebar = (props) => {
  const [activeMenuStyle, setActiveMenuStyle] = useState({});
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    if (props.menu.url === props.layout.active_menu) {
      setActiveMenuStyle({
        color: "#1F2D3D",
        fontWeight: 500,
        borderLeft: `4px solid ${props.layout.driving_color}`,
      });
      setActiveMenu(true);
    } else {
      setActiveMenuStyle({});
      setActiveMenu(false);
    }
  }, [props.layout.active_menu]);

  let history = useHistory();

  const checkIfRedirect = (url) => {
    if (url !== undefined && url !== "" && url !== null) {
      return true;
    } else {
      return false;
    }
  };
  const checkIfIcon = (icon) => {
    if (icon !== undefined && icon !== "" && icon !== null) {
      return true;
    } else {
      return false;
    }
  };
  const checkIfChildren = (children) => {
    if (children !== undefined && children.length !== 0 && children !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <p
        style={{
          ...props.styleMenuItem,
          ...props.extraStyle,
          ...activeMenuStyle,
        }}
        className="underlineOnHover noSelect"
        onClick={() => {
          if (checkIfRedirect(props.menu.url)) {
            history.push(`/dashboard/${props.menu.url}`);
          } else {
            props.setIsOpen(!props.isOpen);
          }
        }}
      >
        {checkIfIcon(props.menu.icon)
          ? iconsMapped(props.menu.icon, activeMenu)
          : null}
        {props.menu.label}
        {checkIfChildren(props.menu.children) && props.isOpen === false ? (
          <MdKeyboardArrowLeft
            style={{ float: "right", marginTop: 5 }}
          ></MdKeyboardArrowLeft>
        ) : checkIfChildren(props.menu.children) && props.isOpen === true ? (
          <MdKeyboardArrowDown
            style={{ float: "right", marginTop: 5 }}
          ></MdKeyboardArrowDown>
        ) : null}
      </p>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, {})(RowMenuSidebar);
