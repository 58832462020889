import { Link } from "react-router-dom";
import React, { useState } from "react";
import FooterLogo from "../../images/logo.png";

export const Footer = (props) => {
  return (
    <div>
      {props.content !== null ? (
        <React.Fragment>
          <footer className="main-footer">
            <div className="auto-container">
              <div className="footer-top">
                <div
                  className="widget-section wow fadeInUp animated"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                  style={{
                    visibility: "visible",
                    animationDuration: "1500ms",
                    animationDelay: "0ms",
                    animationName: "none",
                  }}
                >
                  <div className="row clearfix">
                    {/* <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget logo-widget">
                        <figure className="footer-logo">
                          <a href="">
                            <img
                              src={FooterLogo}
                              alt
                              style={{ width: "35px", heigth: "auto" }}
                            />
                            <span
                              style={{
                                marginLeft: 8,
                                fontWeight: "bold",
                                color: "#3b3b3b",
                                fontSize: 20,
                              }}
                            >
                              SEVAT GROUP®
                            </span>
                          </a>
                        </figure>
                        <div className="text">
                          <p>
                            <i>{props.content.footer1}</i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                      <div className="footer-widget links-widget">
                        <div className="widget-title">
                          <h3>{props.content.services_title}</h3>
                        </div>
                        <div className="widget-content">
                          <ul>
                            <li>
                              <Link to="/teams">{props.content.teams}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 footer-column">
                      <div className="footer-widget contact-widget">
                        <div className="widget-title">
                          <h3>{props.content.contact_info}</h3>
                        </div>
                        <div className="widget-content">
                          <ul>
                            <li>Corso Unione Sovietica 612/15/c, Torino 10135 - Italy</li>
                            <li>
                              <a href="tel:0113053492">+39 0113053492</a>
                            </li>
                            <li>
                              <a href="mailto:segreteria@sevatgroup.com">
                                segreteria@sevatgroup.com
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom clearfix">
                <div className="copyright pull-left">
                  <p>
                    <a>SEVAT SRL</a> © {new Date().getFullYear()} All Rights
                    Reserved – VAT IT11515720016
                  </p>
                </div>
                <ul className="footer-nav pull-right">
                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cookie_policy">Cookie Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Footer;
