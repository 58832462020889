import React from "react";
import { connect } from "react-redux";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

export const NavbarMain = (props) => {
  return (
    <React.Fragment>
      {props.width > 1200 ? (
        <NavbarDesktop {...props} props={props}></NavbarDesktop>
      ) : (
        <NavbarMobile {...props} props={props}></NavbarMobile>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  width: state.windowDimension.width,
});

export default connect(mapStateToProps)(NavbarMain);
