import {
  OPEN_SIDEBAR,
  OPEN_SIDEBAR_WITHOUT_CLICK,
  CLOSE_SIDEBAR,
  HIDE_SIDEBAR,
  UPDATE_LAYOUT,
} from "./types";

export const openSidebar = () => async (dispatch) => {
  dispatch({
    type: OPEN_SIDEBAR,
  });
};
export const openSidebarWithoutClick = () => async (dispatch) => {
  dispatch({
    type: OPEN_SIDEBAR_WITHOUT_CLICK,
  });
};

export const closeSidebar = () => async (dispatch) => {
  dispatch({
    type: CLOSE_SIDEBAR,
  });
};

export const hideSidebar = () => async (dispatch) => {
  dispatch({
    type: HIDE_SIDEBAR,
  });
  dispatch({
    type: UPDATE_LAYOUT,
    payload: { key: "margin_content_from_sidebar", value: 10 },
  });
  dispatch({
    type: UPDATE_LAYOUT,
    payload: { key: "padding_content_from_navbar", value: 5 },
  });
};

export const updateLayout = (key, value) => async (dispatch) => {
  dispatch({
    type: UPDATE_LAYOUT,
    payload: { key, value },
  });
};
