import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SevatLogo from "../../../images/sevat_logo.png";

export const Avatar = (props) => {
  return (
    <Link to={`/dashboard/homepage`}>
      <img
        src={SevatLogo}
        alt="pic"
        style={{
          borderRadius: "50%",
          width: 21,
          margin: "18px 0px 11px -4px",
        }}
      ></img>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Avatar);
