import React from "react";
import { connect } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/auth";

export const DropdownAndJobTitle = (props) => {
  return (
    <div style={{ padding: "0px 24px 5px 24px" }}>
      <UncontrolledButtonDropdown>
        <DropdownToggle
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#1F2D3D",
            padding: 0,
            fontSize: 14,
            boxShadow: "none",
          }}
        >
          {props.auth.username}
          <MdKeyboardArrowDown
            style={{ marginLeft: 5, marginTop: "-2px", fontSize: 15 }}
          ></MdKeyboardArrowDown>
        </DropdownToggle>
        <DropdownMenu>
          {/* {props.auth.is_staff === true ? (
            <>
              <DropdownItem
                tag={Link}
                to={`/dashboard/account`}
                style={{
                  color: "#8493A5",
                  fontSize: 14,
                  padding: "5px 24px",
                }}
                className="dropdownItem"
              >
                Account
              </DropdownItem>

              <DropdownItem divider />
            </>
          ) : null} */}

          <DropdownItem
            onClick={() => props.logout()}
            style={{
              color: "#8493A5",
              fontSize: 14,
              padding: "4px 24px",
            }}
            className="dropdownItem"
          >
            <FaSignOutAlt
              style={{
                color: "#8493A5",
                marginRight: 10,
                marginTop: "-3px",
                fontSize: 13,
              }}
              className="dropdownItemInner"
            ></FaSignOutAlt>
            Sign Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <div style={{ color: "#8493A5", fontSize: 12 }}>
        {props.auth.job_title}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, { logout })(DropdownAndJobTitle);
