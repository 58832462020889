import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getNewsDetails, deleteNews } from "../../actions/news";
import Button from "@mui/material/Button";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import {
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Loading from "../../layout/Loading";

export const Homepage = (props) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);

  const [newsList, setNewsList] = useState(null);
  useEffect(() => {
    setNewsList(props.newsList);
  }, [props.newsList]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          margin: 10,
          padding: 10,
        }}
      >
        <Link to={"/dashboard/editor/"} style={{ textDecoration: "none" }}>
          <Button variant="outlined" onClick={() => {}}>
            Crea
          </Button>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          // justifyContent: "space-between",
        }}
      >
        {newsList !== null ? (
          newsList.map((el, index) => {
            return (
              <div
                style={{
                  minWidth: "300px",
                  maxWidth: "300px",
                  margin: 10,
                  padding: 10,
                  backgroundColor: "#ffff",
                  borderRadius: "5px",
                  boxShadow:
                    "rgb(69 104 129 / 33%) 0px 24px 24px -18px, rgb(114 119 160 / 12%) 0px 945px 0px",
                }}
              >
                {el.anteprima !== "" ? (
                  <img
                    src={`data:image;base64,${el.anteprima}`}
                    alt=""
                    style={{
                      height: "200px",
                      maxWidth: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    style={{
                      height: "200px",
                      maxWidth: "100%",
                      backgroundColor: "dimgrey",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                )}
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#1A1A1A",
                  }}
                >
                  {el.titolo}
                </p>
                <p style={{ fontSize: 14 }}>{el.sottotitolo}</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title="Modifica" style={{ cursor: "pointer" }}>
                    <Link
                      id={el.id}
                      to={`/dashboard/editor/${el.id}`}
                      onClick={() => {}}
                    >
                      <div>
                        <FaPencilAlt color="#fbbc48"></FaPencilAlt>
                      </div>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Elimina" style={{ cursor: "pointer" }}>
                    <div>
                      <FaTrashAlt
                        color="#dd2626"
                        onClick={() => {
                          setOpen(true);
                          setId(el.id);
                        }}
                      ></FaTrashAlt>
                    </div>
                  </Tooltip>
                </div>
                {index % 3 ? (
                  <div
                    key={"break"}
                    className="break"
                    style={{ flexBasis: "100%", height: 0 }}
                  ></div>
                ) : null}
              </div>
            );
          })
        ) : (
          <Loading></Loading>
        )}
      </div>
      <Modal isOpen={open}>
        <ModalHeader>
          Sei sicuro di voler eliminare questo elemento?
        </ModalHeader>
        <ModalFooter>
          <ReactButton
            className="btn"
            color="danger"
            onClick={() => {
              setOpen(false);
              props.deleteNews(id);
            }}
          >
            Elimina
          </ReactButton>
          <ReactButton
            className="btn"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Indietro
          </ReactButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newsList: state.news.listaNews,
});

export default connect(mapStateToProps, { getNewsDetails, deleteNews })(
  Homepage
);
