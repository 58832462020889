import { cloneDeep } from "lodash";
import { isPlainObject } from "./isPlainObject";

export const checkLowestKey = (obj) => {
  if (Object.keys(obj).length > 0) {
    var is_done = false;
    var current_obj = cloneDeep(obj);
    var current_key = Object.keys(obj)[0];
    while (is_done === false) {
      if (!isPlainObject(current_obj[current_key])) {
        is_done = true;
      } else {
        current_obj = current_obj[current_key];
        current_key = Object.keys(current_obj)[0];
      }
    }
    var text = String(current_obj[Object.keys(current_obj)[0]]);
    // traduzione
    if (current_key === "non_field_errors") {
      current_key = "Attenzione";
    }
    if (text === "Unable to log in with provided credentials.") {
      text = "Credenziali errate";
    }
    // if(String())

    return `${current_key}: ${text}`;
  }
};
