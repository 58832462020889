import Swal from "sweetalert2";

export default function SuccessAlert(msg) {
  if (
    msg.includes("La tabella permette di definire le capacità massime") ||
    msg.includes(
      "La soglia di criticità quantifica il margine rispetto alla condizione di criticità"
    )
  ) {
    Swal.fire({
      title: msg,
      icon: "info",
      confirmButtonText: "Close",
      customClass: {
        confirmButton: "btn btn-primary",
        title: "MuiTypography-h6"
      },
      buttonsStyling: false,
      width: "1000px"
    });
  } else {
    Swal.fire({
      title: msg,
      icon: "info",
      confirmButtonText: "Close",
      customClass: {
        confirmButton: "btn btn-primary",
        title: "MuiTypography-h6"
      },
      buttonsStyling: false
    });
  }
}
