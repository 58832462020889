export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_SIDEBAR_WITHOUT_CLICK = "OPEN_SIDEBAR_WITHOUT_CLICK";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const IS_LOADING = "IS_LOADING";
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_DETAILS = "GET_NEWS_DETAILS";
export const CREA_NEWS = "CREA_NEWS";
export const MODIFICA_NEWS = "MODIFICA_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const TEST_VARIABLE = "TEST_VARIABLE";
export const SET_WINDOW_DIMENSION = "SET_WINDOW_DIMENSION";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_MAPPE = "GET_MAPPE";

// export const API_URL = "http://sitiweb-sevatgroup.gdpanalytics.local/api";
export const API_URL = "https://sevatgroup.com/api";
