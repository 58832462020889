import React, { useLayoutEffect, useState } from "react";
import { setWindowDimension } from "../../../Dashboard/actions/windowDimension";
import { connect } from "react-redux";

export const ViewPortDimensions = (props) => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  props.setWindowDimension(size);
  return "";
};

export default connect(null, { setWindowDimension })(ViewPortDimensions);
