import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import NavbarMain from "../components/NavBar/NavbarMain";
import Footer from "../components/Footer/Footer";
import axios from "axios";
import { tokenConfig } from "../utils/tokenConfig";
import { API_URL } from "../../Dashboard/actions/types";
import { getLanguage } from "../utils/getLanguage";

export const TermsOfService = (props) => {
  const [open, setOpen] = useState("");
  const [content, setContent] = useState(null);

  useEffect(async () => {
    setContent(null);
    const request = await axios.post(
      `${API_URL}/translation/`,
      {
        to_code: getLanguage(props.language),

        page: "account_service",
      },
      tokenConfig()
    );
    setContent(request.data);
  }, [props.language]);
  let constrain = 150;
  let mouseOverContainer = document.getElementById("ex1");
  let ex1Layer = document.getElementById("ex1-layer");
  const [calcX, setCalcX] = useState(0);
  const [calcY, setCalcY] = useState(0);
  const [transition, setTransition] = useState("");
  if (mouseOverContainer !== null) {
    function transforms(x, y, el) {
      setTransition("");
      let box = el.getBoundingClientRect();
      setCalcX(((y - box.y - box.height / 2) * 3) / constrain);
      setCalcY(-((x - box.x - box.width / 2) * 3) / constrain);
      return (
        "perspective(300px) " +
        "   rotateX(" +
        calcX +
        "deg) " +
        "   rotateY(" +
        calcY +
        "deg) " +
        "scale3d(1,1,1)" +
        transition
      );
    }

    function transformElement(el, xyEl) {
      el.style.transform = transforms.apply(null, xyEl);
    }

    mouseOverContainer.onmousemove = function (e) {
      let xy = [e.clientX, e.clientY];
      let position = xy.concat([ex1Layer]);

      window.requestAnimationFrame(function () {
        transformElement(ex1Layer, position);
      });
    };
    mouseOverContainer.onmouseout = function (e) {
      window.requestAnimationFrame(function () {
        document.getElementById(
          "ex1-layer"
        ).style.transform = `perspective(300px) rotateX(0deg) rotateY(0deg) scale3d(1,1,1)`;
      });
      setTransition(
        "transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s"
      );
      setCalcX(0);
      setCalcY(0);
    };
  }
  return (
    <div>
      {content !== null ? (
        <div>
          {/* preloader */}
          <div className="preloader" style={{ display: "none" }} />
          {/* preloader */}

          <NavbarMain
            {...props}
            history={props.history}
            content={content}
          ></NavbarMain>

          {/*Page Title*/}
          <section
            className="page-title bg-color-1 text-center"
            style={{ paddingTop: "6rem", paddingBottom: "6rem" }}
          >
            <div
              className="pattern-layer"
              style={{
                backgroundImage: "url(assets/images/shape/pattern-18.png)",
              }}
            />
            <div className="auto-container">
              <div className="content-box">
                <h1>Cookie Policy</h1>
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/homepage">Home</a>
                  </li>
                  <li>Cookie Policy</li>
                </ul>
              </div>
            </div>
          </section>
          {/*End Page Title*/}
          {/* service-section */}
          <section className="service-section service-page sec-pad">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-12" style={{ padding: "20px 20px 0px" }}>
                  <div style={{ color: "rgb(117, 123, 140)" }}>
                    <div>
                      We use cookies to make our website easier and more
                      intuitive. The data collected through cookies are used to
                      make the browsing experience more enjoyable and more
                      efficient in the future.
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "2px",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgb(49, 62, 91)",
                      }}
                    >
                      What are cookies?
                    </div>
                    <div>
                      Cookies are small text files sent from the website to the
                      interested party's terminal (usually to the browser),
                      where they are stored before being re-transmitted to the
                      website at the next visit by the same user. A cookie
                      cannot retrieve any other data from the user's hard drive
                      or transmit computer viruses or acquire email addresses.
                      Each cookie is unique to the user's web browser. Some of
                      the functions of cookies can be delegated to other
                      technologies. In this document, the term 'cookie' refers
                      to both cookies, proper, and all similar technologies.
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "2px",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgb(49, 62, 91)",
                      }}
                    >
                      Cookie types
                    </div>
                    <div>
                      There are 2 different types of cookies:
                      <ol>
                        <li
                          style={{
                            fontWeight: "500",
                            marginTop: "8px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "auto",
                            fontWeight: "bold",
                          }}
                        >
                          <div>Technical cookie</div>
                          <div style={{ fontWeight: "normal" }}>
                            Technical cookies are those used for the sole
                            purpose of "carrying out the transmission of a
                            communication over an electronic communications
                            network, or to the extent strictly necessary for the
                            provider of an information society service
                            explicitly requested by the subscriber or user to
                            provide this service "(see art. 122, paragraph 1, of
                            the Code). They are not used for other purposes and
                            are normally installed directly by the website owner
                            or manager. They can be divided into:
                            <ul>
                              <li
                                style={{
                                  marginTop: "2px",
                                  marginLeft: "30px",
                                  display: "list-item",
                                  listStyle: "circle",
                                }}
                              >
                                navigation or session cookies, which guarantee
                                normal navigation and use of the website
                                (allowing, for example, to make a purchase or
                                authenticate to access restricted areas); they
                                are in fact necessary for the proper functioning
                                of the website;
                              </li>
                              <li
                                style={{
                                  marginTop: "2px",
                                  marginLeft: "30px",
                                  display: "list-item",
                                  listStyle: "circle",
                                }}
                              >
                                analytics cookies, similar to technical cookies
                                when used directly by the website operator to
                                collect information, in aggregate form, on the
                                number of users and how they visit the website,
                                in order to improve website performance;
                              </li>
                              <li
                                style={{
                                  marginTop: "2px",
                                  marginLeft: "30px",
                                  display: "list-item",
                                  listStyle: "circle",
                                }}
                              >
                                functional cookies, which allow the user to
                                browse according to a series of selected
                                criteria (for example, the language, the
                                products selected for purchase) in order to
                                improve the service provided. For the
                                installation of these cookies, the prior consent
                                of the users is not required (more information
                                in the paragraph on managing cookies below).
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li
                          style={{
                            fontWeight: "500",
                            marginTop: "8px",
                            marginTop: "8px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "auto",
                            fontWeight: "bold",
                          }}
                        >
                          <div>Profiling cookies</div>
                          <div style={{ fontWeight: "normal" }}>
                            Profiling cookies are designed to create user
                            profiles and are used in order to send advertising
                            messages in line with the preferences expressed by
                            the user while surfing the net. These technologies
                            do not collect user names, email addresses,
                            telephone numbers, physical addresses. The consent
                            of the interested party is required for the use of
                            profiling cookies. In the case of third-party
                            cookies, the website does not have direct control of
                            the individual cookies and cannot control them (it
                            cannot install them directly or delete them). You
                            can still manage these cookies through your browser
                            settings (follow the instructions below), or the
                            websites listed in the "Cookie management" section.
                          </div>
                        </li>
                      </ol>
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "2px",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgb(49, 62, 91)",
                      }}
                    >
                      List of technical cookies on the Sevat srl website
                    </div>
                    <div>
                      The Sevat srl website uses only system cookies to
                      guarantee the user a better browsing experience; these
                      cookies are essential for the correct use of the website.
                      You can disable these cookies from your browser by
                      following the instructions in the dedicated paragraph, but
                      you will compromise your experience on the website and we
                      will not be able to respond to malfunctions.
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "2px",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgb(49, 62, 91)",
                      }}
                    >
                      Configurations for disabling cookies
                    </div>
                    <div>
                      The user can choose not to enable the execution of cookies
                      and, therefore, cancel the profiling activity for
                      promotional purposes by acting on the settings of his
                      browser. Instructions for disabling cookies relating to
                      the most used browsers:
                      <ul>
                        <li
                          style={{
                            marginTop: "5px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "outside",
                          }}
                        >
                          <a
                            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=it"
                            target="_blank"
                            className="aTagUnderline"
                          >
                            Chrome
                          </a>
                        </li>
                        <li
                          style={{
                            marginTop: "5px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "outside",
                          }}
                        >
                          <a
                            href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&amp;redirectlocale=it"
                            target="_blank"
                            className="aTagUnderline"
                          >
                            Firefox
                          </a>
                        </li>
                        <li
                          style={{
                            marginTop: "5px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "outside",
                          }}
                        >
                          <a
                            href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
                            target="_blank"
                            className="aTagUnderline"
                          >
                            Internet Explorer
                          </a>
                        </li>
                        <li
                          style={{
                            marginTop: "5px",
                            marginLeft: "30px",
                            display: "list-item",
                            listStyle: "outside",
                          }}
                        >
                          <a
                            href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
                            target="_blank"
                            className="aTagUnderline"
                          >
                            Safari
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "2px",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgb(49, 62, 91)",
                      }}
                    >
                      More information
                    </div>
                    <div>
                      For more information regarding cookies, please send an
                      email to{" "}
                      <a
                        className="aTagUnderline"
                        href="mailto:privacy@sevatgroup.com"
                      >
                        privacy@sevatgroup.com
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* main-footer */}
          <Footer content={content} />
          {/* main-footer end */}

          <button
            className={"scroll-top scroll-to-target " + open}
            data-target="html"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            style={{ right: "10px" }}
          >
            <span className="fa fa-arrow-up"></span>
          </button>
          {/* jequery plugins */}
          {/* main-js */}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  width: state.windowDimension.width,
  language: state.layout.language,
});

export default connect(mapStateToProps)(TermsOfService);
