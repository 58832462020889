import Swal from "sweetalert2";

export default function ErrorAlert(msg) {
  Swal.fire({
    title: msg,
    icon: "error",
    confirmButtonText: "CLOSE",
    customClass: {
      confirmButton: "btn btn-danger",
      title: "MuiTypography-h6",
    },
    buttonsStyling: false,
  });
}
