import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SevatLogo from "../../../images/sevat_logo.png";

export const Avatar = (props) => {
  const [fotoProfilo, setFotoProfilo] = useState("");
  useEffect(() => {
    setFotoProfilo(props.auth.foto_profilo);
  }, [props.auth]);
  return (
    <Link to={`/dashboard/homepage`}>
      <img
        src={SevatLogo}
        alt="pic"
        style={{
          borderRadius: "50%",
          width: 107,
          padding: "22px 24px",
          marginBottom: "-10px",
        }}
      ></img>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Avatar);
