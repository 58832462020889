import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import errors from "./errors";
import isLoading from "./isLoading";
import news from "./news";
import windowDimension from "./windowDimension";
import mappe from "./mappe";

export default combineReducers({
  auth,
  layout,
  errors,
  isLoading,
  news,
  windowDimension,
  mappe,
});
