import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMappe, deleteMappe, createMappe } from "../../actions/mappe";
import {
  Card,
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FaTrash } from "react-icons/fa";
import { TextField } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import InfoMap from "../../images/example.png";

export const Mappe = (props) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoMap, setOpenInfoMap] = useState(false);
  const [modificato, setModificato] = useState(0);
  const [payload, setPayload] = useState({
    indirizzo: "",
    x: null,
    y: null,
  });

  useEffect(async () => {
    await props.getMappe();
  }, [modificato]);

  return (
    <div>
      <Card style={{ height: 800, padding: 20, overflowY: "auto" }}>
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1
                style={{
                  fontSize: 26,
                  fontWeight: 400,
                  margin: 0,
                  marginLeft: 10,
                }}
              >
                <div>
                  Punti su Mappa
                  <FaQuestionCircle
                    onClick={() => {
                      setOpenInfoMap(true);
                    }}
                    style={{
                      color: "#4580EA",
                      fontSize: 12,
                      marginTop: "-15px",
                      cursor: "pointer",
                      marginLeft: 3,
                    }}
                  ></FaQuestionCircle>
                </div>
              </h1>
            </div>
          </div>
          <ReactButton
            style={{
              marginLeft: "auto",
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#007bff",
              borderColor: "#007bff",
            }}
            color="primary"
            onClick={() => setOpen(true)}
          >
            Aggiungi
          </ReactButton>
        </div>
        {props.puntiMappa !== null ? (
          <Row>
            {props.puntiMappa.map((punto) => {
              return (
                <Col lg="6" md="6" sm="12" style={{ marginBottom: 10 }}>
                  <Card style={{ height: 160, padding: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                      }}
                    >
                      <p>
                        {punto.nome} {punto.indirizzo}
                      </p>
                      <FaTrash
                        color={"red"}
                        style={{ marginTop: 5, cursor: "pointer" }}
                        onClick={async () => {
                          setOpenDelete(true);
                          setId(punto.id);
                        }}
                      ></FaTrash>
                    </div>
                    <p>
                      x: {punto.x} y: {punto.y}
                    </p>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : null}
      </Card>
      <Modal isOpen={open}>
        <ModalHeader>Imposta Anteprima</ModalHeader>
        <ModalBody>
          <div style={{ padding: 10 }}>
            <TextField
              style={{ width: "100%" }}
              label={"Nome (opzionale)"}
              value={payload.nome}
              onChange={(e) => {
                setPayload({ ...payload, nome: e.target.value });
              }}
              variant="outlined"
            />
            <TextField
              style={{ width: "100%", marginTop: 20 }}
              label={"Indirizzo*"}
              value={payload.indirizzo}
              onChange={(e) => {
                setPayload({ ...payload, indirizzo: e.target.value });
              }}
              variant="outlined"
            />
            <TextField
              style={{ width: "100%", marginTop: 20 }}
              label={"Coordinata x*"}
              value={payload.x}
              onChange={(e) => {
                setPayload({ ...payload, x: e.target.value });
              }}
              variant="outlined"
            />
            <TextField
              style={{ width: "100%", marginTop: 20 }}
              label={"Coordinata y*"}
              value={payload.y}
              onChange={(e) => {
                setPayload({ ...payload, y: e.target.value });
              }}
              variant="outlined"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ReactButton
            style={{
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#007bff",
              borderColor: "#007bff",
            }}
            variant="outlined"
            onClick={async () => {
              setOpen(false);
              var form_data = new FormData();

              for (var key in payload) {
                form_data.append(key, payload[key]);
              }
              await props.createMappe(form_data);
              setModificato(modificato + 1);
              setPayload({
                indirizzo: "",
                x: null,
                y: null,
              });
            }}
          >
            Procedi
          </ReactButton>
          <ReactButton
            style={{
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#6c757d",
              borderColor: "#6c757d",
            }}
            color="secondary"
            onClick={() => {
              setOpen(false);
              setPayload({
                indirizzo: "",
                x: null,
                y: null,
              });
            }}
          >
            Indietro
          </ReactButton>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openDelete}>
        <ModalHeader>Sicuro di voler eliminare questo punto?</ModalHeader>
        <ModalFooter>
          <ReactButton
            style={{
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#dc3545",
              borderColor: "#dc3545",
            }}
            variant="outlined"
            color="danger"
            onClick={async () => {
              await props.deleteMappe(id);
              setOpenDelete(false);
              setModificato(modificato + 1);
              setPayload({
                indirizzo: "",
                x: null,
                y: null,
              });
            }}
          >
            Elimina
          </ReactButton>
          <ReactButton
            style={{
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#6c757d",
              borderColor: "#6c757d",
            }}
            color="secondary"
            onClick={() => {
              setOpenDelete(false);
              setPayload({
                indirizzo: "",
                x: null,
                y: null,
              });
            }}
          >
            Indietro
          </ReactButton>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openInfoMap} size="lg">
        <ModalHeader>Info</ModalHeader>
        <ModalBody>
          Per inserire un nuovo punto andare su Google Maps e selezionare il
          luogo di interesse.
          <img
            src={InfoMap}
            alt="infoMap"
            style={{ height: "auto", width: "100%" }}
          ></img>
        </ModalBody>
        <ModalFooter>
          <ReactButton
            style={{
              border: "1px solid",
              color: "#fff",
              backgroundColor: "#6c757d",
              borderColor: "#6c757d",
            }}
            color="secondary"
            onClick={() => {
              setOpenInfoMap(false);
            }}
          >
            Chiudi
          </ReactButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  puntiMappa: state.mappe.listaMappe,
});

export default connect(mapStateToProps, { getMappe, deleteMappe, createMappe })(
  Mappe
);
