import { SET_WINDOW_DIMENSION } from "../actions/types";

const initialState = {
  width: null,
  height: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WINDOW_DIMENSION:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      };
    default:
      return state;
  }
}
