import {
  GET_NEWS,
  GET_NEWS_DETAILS,
  MODIFICA_NEWS,
  CREA_NEWS,
  DELETE_NEWS,
} from "../actions/types";

// Initial personale redux state
const initialState = {
  listaNews: null,
  newsDetails: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return { ...state, listaNews: action.payload };
    case GET_NEWS_DETAILS:
      return {
        ...state,
        newsDetails: action.payload,
      };
    case MODIFICA_NEWS:
      const listaNewsMod = state.listaNews.map((n) => {
        if (n.id === parseFloat(action.payload.id)) {
          return action.payload.data;
        } else {
          return n;
        }
      });
      return {
        ...state,
        listaNews: listaNewsMod,
        newsDetails: null,
      };
    case CREA_NEWS:
      return {
        ...state,
        listaNews: [...state.listaNews, action.payload],
        newsDetails: null,
      };
    case DELETE_NEWS:
      var listaNewsDel = state.listaNews.filter((x) => x.id !== action.payload);
      return {
        ...state,
        listaNews: listaNewsDel,
        newsDetails: null,
      };
    default:
      return state;
  }
}
