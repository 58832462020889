import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateLayout } from "../../../actions/layout";
import MenuSidebar from "../SidebarOpened/MenuSidebar";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import Avatar from "../SidebarClosed/Avatar";
import Footer from "./Footer";

function SidebarMobile(props) {
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    if (activeMenu === null) {
      setActiveMenu(props.layout.active_menu);
    } else {
      props.updateLayout("is_opened_sidebar", false);
    }
  }, [props.layout.active_menu]);
  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        zIndex: 10,
      }}
      className="fadeInLinear"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-18px",
          padding: "17px 25px 0px 25px",
          width: "100%",
        }}
      >
        <FaArrowAltCircleLeft
          onClick={() => props.updateLayout("is_opened_sidebar", false)}
          style={{ fontSize: 16, cursor: "pointer", marginTop: "23px" }}
        ></FaArrowAltCircleLeft>

        <Avatar></Avatar>
      </div>

      {/* primary menus*/}
      <div style={{ padding: "29px 0px" }}>
        {props.layout.menus_primary.map((obj, index) => {
          return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
        })}
      </div>
      {props.layout.menus_secondary !== undefined &&
      props.layout.menus_secondary.length > 0 ? (
        <div>
          <hr
            style={{
              margin: "-15px 28px",
              borderTop: "1px solid #949494",
            }}
          ></hr>
          {/* secondary menus*/}
          <div style={{ padding: "39px 0px" }}>
            {props.layout.menus_secondary.map((obj, index) => {
              return <MenuSidebar key={index} menu={obj}></MenuSidebar>;
            })}
          </div>
        </div>
      ) : null}
      <Footer></Footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps, { updateLayout })(SidebarMobile);
