// this menu can have up 3 levels
// icons are passed in level 1 through a string taken from react-icons (the mapping string/component is done in iconsmapped.js)
//  --> make sure to use only react-icons from Ant Design, Bootstrap Icons, Font Awesome and Material Design
// to create level 2 and 3 nest the array in the object using the key "children"
export const menusPrimarySidebar = [
  {
    label: "Homepage",
    url: "homepage",
    icon: "FaHome",
  },
  {
    label: "Mappe",
    url: "mappe",
    icon: "FaMapMarkedAlt",
  },
];
