import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import { iconsMapped } from "../../../utils/iconsMapped";
import RowMenuSidebar from "./RowMenuSidebar";

export const SubMenuSidebar = (props) => {
  const [isOpen1, setIsOpen1] = useState(false);

  let history = useHistory();

  const checkIfIcon = (icon) => {
    if (icon !== undefined && icon !== "" && icon !== null) {
      return true;
    } else {
      return false;
    }
  };
  const checkIfChildren = (children) => {
    if (children !== undefined && children.length !== 0 && children !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {/* level 2 */}
      <RowMenuSidebar
        menu={props.menu}
        isOpen={isOpen1}
        setIsOpen={setIsOpen1}
        extraStyle={{ paddingLeft: 55 }}
        styleMenuItem={props.styleMenuItem}
      ></RowMenuSidebar>

      {/* level 3 */}
      {checkIfChildren(props.menu.children) ? (
        <Collapse isOpen={isOpen1}>
          {props.menu.children.map((obj, index) => {
            return (
              <p
                key={index}
                style={
                  props.layout.active_menu === obj.url
                    ? {
                        ...props.styleMenuItem,
                        paddingLeft: 70,
                        // color: "white",
                        fontWeight: 500,
                        borderLeft: `4px solid ${props.layout.driving_color}`,
                      }
                    : { ...props.styleMenuItem, paddingLeft: 70 }
                }
                className="underlineOnHover noSelect"
                onClick={() => {
                  history.push(`/dashboard/${obj.url}`);
                }}
              >
                {checkIfIcon(obj.icon) ? iconsMapped(obj.icon) : null}
                {obj.label}
              </p>
            );
          })}
        </Collapse>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, {})(SubMenuSidebar);
