import React from "react";
import LogoGDP from "../../../images/logo_gdp.png";

export const Footer = () => {
  return (
    <div
      style={{
        bottom: 0,
        position: "absolute",
        padding: "20px 22px",
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <img src={LogoGDP} style={{ width: "25px", float: "left" }}></img>
      <div
        style={{
          marginTop: 13,
          marginLeft: 35,
          color: "#8493A5",
          fontSize: 13,
        }}
      >
        Boosted by{" "}
        <a
          href="https://www.gdpanalytics.com"
          target="_blank"
          style={{ color: "#1F2D3D" }}
        >
          GDP Analytics
        </a>
      </div>
    </div>
  );
};

export default Footer;
