import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GoThreeBars } from "react-icons/go";
import { FaHome, FaPowerOff, FaUsers } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { openSidebar, closeSidebar } from "../../actions/layout";
import { logout } from "../../actions/auth";
import Tooltip from "@mui/material/Tooltip";
import { useWindowDimensions } from "../../utils/useWindowDimensions";

export const Navbar = (props) => {
  const location = useLocation();
  const [listPage, setListPage] = useState([]);
  const { width, height } = useWindowDimensions();

  // detect on change Route --> update on top the page Displayed
  useEffect(() => {
    var link = location.pathname;
    link = link.split(`dashboard/`)[1];
    link = link.split("/");
    var list_name = link.map((s) => {
      s = s.replace("_", " ");

      return s.charAt(0).toUpperCase() + s.slice(1);
    });
    setListPage(list_name);
  }, [location]);

  const dispatchResizeWindow = () => {
    // don't run the function on mobile because of overflow (no need to adjust size of plotly)
    if (width > props.layout.mobile_layout_width) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 800);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "#f9fafc",
        height: props.layout.height_navbar,
        left: props.layout.width_sidebar,
        right: "0px",
        transition: props.layout.transition_speed,
        padding: "17px 25px",
        zIndex: 9,
        // background: "white",
        // boxShadow: "5px 0px 5px 0px #B6B6B6",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          color: "#1F2D3D",
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: 15 }}>
              <GoThreeBars
                style={{
                  fontSize: width > props.sidebar_threshold_width ? 12 : 16,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatchResizeWindow(); // this function is used to trigger a fake "resize" event. This is done to resize plotly charts automatically
                  if (props.layout.is_opened_sidebar) {
                    props.closeSidebar();
                  } else {
                    props.openSidebar();
                  }
                }}
              ></GoThreeBars>
            </div>
            {width > props.sidebar_threshold_width ? (
              <div style={{ flex: 40 }}>
                <Link to={`/dashboard/homepage`}>
                  <FaHome
                    style={{
                      fontSize: 12,
                      color: "#1F2D3D",
                      height: "100%",
                      float: "left",
                    }}
                  ></FaHome>
                </Link>
                {width > props.sidebar_threshold_width + 200
                  ? listPage.map((s) => (
                      <React.Fragment key={s}>
                        <MdChevronRight
                          style={{
                            fontSize: 15,
                            height: "100%",
                            float: "left",
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                        ></MdChevronRight>
                        <div
                          style={{
                            float: "left",
                          }}
                        >
                          {s}
                        </div>
                      </React.Fragment>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ justifyContent: "end" }}>
          <div style={{ display: "flex" }}>
            {/* logout */}
            <Tooltip title="Logout">
              <div style={{ display: "inline" }}>
                <FaPowerOff
                  onClick={() => props.logout()}
                  style={{
                    fontSize: 13,
                    cursor: "pointer",
                  }}
                ></FaPowerOff>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
  sidebar_threshold_width: state.layout.sidebar_threshold_width,
  mobile_layout_width: state.layout.mobile_layout_width,
});

export default connect(mapStateToProps, {
  openSidebar,
  closeSidebar,
  logout,
})(Navbar);
