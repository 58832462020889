import React from "react";
import { connect } from "react-redux";
import SidebarOpened from "./SidebarOpened/SidebarOpened";
import SidebarClosed from "./SidebarClosed/SidebarClosed";
import SidebarMobile from "./SidebarMobile/SidebarMobile";

export const Sidebar = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: props.layout.width_sidebar,
        transition: props.layout.transition_speed,
        zIndex: 10,
        background: "white",
        boxShadow: "0px 0px 10px -4px #888888",
      }}
    >
      {props.layout.is_opened_sidebar === false ? (
        <SidebarClosed></SidebarClosed>
      ) : props.layout.width_sidebar > 0 ? (
        <SidebarOpened></SidebarOpened>
      ) : (
        <SidebarMobile></SidebarMobile>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

export default connect(mapStateToProps)(Sidebar);
