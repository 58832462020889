import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { iconsMapped } from "../../../utils/iconsMapped";
import { connect } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";

export const MenuSidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  let history = useHistory();

  const checkIfRedirect = (url) => {
    if (url !== undefined && url !== "" && url !== null) {
      return true;
    } else {
      return false;
    }
  };
  const checkIfIcon = (icon) => {
    if (icon !== undefined && icon !== "" && icon !== null) {
      return true;
    } else {
      return false;
    }
  };
  const checkIfChildren = (children) => {
    if (children !== undefined && children.length !== 0 && children !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    // 1 level: icon level
    <div
      style={{
        cursor: "pointer",
        width: "100%",
        padding: "5px 0px 15px 0px",
      }}
      onClick={() => {
        if (checkIfRedirect(props.menu.url)) {
          history.push(`/dashboard/${props.menu.url}`);
        }
      }}
      onMouseEnter={() => {
        if (checkIfChildren(props.menu.children) === true) {
          setIsOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (checkIfChildren(props.menu.children) === true) {
          setIsOpen(false);
        }
      }}
    >
      {checkIfIcon(props.menu.icon)
        ? iconsMapped(props.menu.icon, false) // when sidebarClosed, never show active menu
        : null}
      {checkIfChildren(props.menu.children) ? (
        // 2 level
        <Dropdown
          isOpen={isOpen}
          style={{
            position: "absolute",
            marginLeft: 35,
            marginTop: "-20px",
          }}
          toggle={() => setIsOpen(!isOpen)}
        >
          <DropdownMenu style={{ borderColor: "#DFDFDF" }}>
            {props.menu.children.map((obj, index) => {
              return (
                <DropdownItem
                  key={index}
                  className="dropdownItemSidebarClosed"
                  style={{ color: "#8493a5", fontSize: 14 }}
                  onClick={() => {
                    if (checkIfRedirect(obj.url)) {
                      history.push(`/dashboard/${obj.url}`);
                    }
                  }}
                  onMouseEnter={() => {
                    if (checkIfChildren(obj.children) === true) {
                      setIsOpen2(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (checkIfChildren(obj.children) === true) {
                      setIsOpen2(false);
                    }
                  }}
                >
                  {obj.label}
                  {/*  3 level */}
                  {checkIfChildren(obj.children) ? (
                    <Dropdown
                      isOpen={isOpen2}
                      style={{
                        position: "absolute",
                        marginLeft: 138,
                        marginTop: "-32px",
                      }}
                      toggle={() => setIsOpen2(!isOpen2)}
                    >
                      <DropdownMenu style={{ borderColor: "#DFDFDF" }}>
                        {obj.children.map((obj2, index2) => {
                          return (
                            <DropdownItem
                              key={index2}
                              className="dropdownItemSidebarClosed"
                              style={{ color: "#8493a5", fontSize: 14 }}
                              onClick={() => {
                                if (checkIfRedirect(obj2.url)) {
                                  history.push(`/dashboard/${obj2.url}`);
                                }
                              }}
                            >
                              {obj2.label}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps, {})(MenuSidebar);
