import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import NavbarMain from "../components/NavBar/NavbarMain";
import Footer from "../components/Footer/Footer";
import axios from "axios";
import { tokenConfig } from "../utils/tokenConfig";
import { API_URL } from "../../Dashboard/actions/types";
import { getLanguage } from "../utils/getLanguage";

export const TermsOfService = (props) => {
  const [open, setOpen] = useState("");
  const [content, setContent] = useState(null);

  useEffect(async () => {
    setContent(null);
    const request = await axios.post(
      `${API_URL}/translation/`,
      {
        to_code: getLanguage(props.language),

        page: "account_service",
      },
      tokenConfig()
    );
    setContent(request.data);
  }, [props.language]);
  let constrain = 150;
  let mouseOverContainer = document.getElementById("ex1");
  let ex1Layer = document.getElementById("ex1-layer");
  const [calcX, setCalcX] = useState(0);
  const [calcY, setCalcY] = useState(0);
  const [transition, setTransition] = useState("");
  if (mouseOverContainer !== null) {
    function transforms(x, y, el) {
      setTransition("");
      let box = el.getBoundingClientRect();
      setCalcX(((y - box.y - box.height / 2) * 3) / constrain);
      setCalcY(-((x - box.x - box.width / 2) * 3) / constrain);
      return (
        "perspective(300px) " +
        "   rotateX(" +
        calcX +
        "deg) " +
        "   rotateY(" +
        calcY +
        "deg) " +
        "scale3d(1,1,1)" +
        transition
      );
    }

    function transformElement(el, xyEl) {
      el.style.transform = transforms.apply(null, xyEl);
    }

    mouseOverContainer.onmousemove = function (e) {
      let xy = [e.clientX, e.clientY];
      let position = xy.concat([ex1Layer]);

      window.requestAnimationFrame(function () {
        transformElement(ex1Layer, position);
      });
    };
    mouseOverContainer.onmouseout = function (e) {
      window.requestAnimationFrame(function () {
        document.getElementById(
          "ex1-layer"
        ).style.transform = `perspective(300px) rotateX(0deg) rotateY(0deg) scale3d(1,1,1)`;
      });
      setTransition(
        "transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s"
      );
      setCalcX(0);
      setCalcY(0);
    };
  }
  return (
    <div>
      {/* preloader */}
      <div className="preloader" style={{ display: "none" }} />
      {/* preloader */}

      <NavbarMain
        {...props}
        history={props.history}
        content={content}
      ></NavbarMain>

      {/*Page Title*/}
      <section
        className="page-title bg-color-1 text-center"
        style={{ paddingTop: "6rem", paddingBottom: "6rem" }}
      >
        <div
          className="pattern-layer"
          style={{ backgroundImage: "url(assets/images/shape/pattern-18.png)" }}
        />
        <div className="auto-container">
          <div className="content-box">
            <h1>Privacy Policy</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/homepage">Home</a>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>
      </section>
      {/*End Page Title*/}
      {/* service-section */}
      <section className="service-section service-page sec-pad">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12" style={{ padding: "20px 20px 0px" }}>
              <div style={{ color: "rgb(117, 123, 140)" }}>
                <div>
                  Sevat srl in accordance with EU Regulation no. 2016/679,
                  containing the instructions to protect the processing of
                  personal data of natural persons and other entities, in the
                  person of its legal representative Gardino Pierfederico,
                  hereinafter referred to as the Data Controller
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "10px 0px",
                  }}
                >
                  <i>informs you</i>
                </div>
                <div>
                  that the personal data provided will be subject to the
                  aforementioned regulation and the company's confidentiality
                  obligations.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Why this notice
                </div>
                <div>
                  The following page describes how to manage the website with
                  reference to the processing of the personal data of users who
                  consult it. This is an information notice also provided
                  pursuant to the GDPR to those who interact with the services
                  directly provided by Sevat srl. The information is provided
                  for the website by Sevat srl as the Data Controller
                </div>
                <div style={{ marginTop: "10px" }}>
                  The Privacy Policy and Standards used for the protection of
                  personal data are based on the following principles:
                  <ol style={{ listStyleType: "upper-roman" }}>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Principle of liability
                      <div style={{ fontWeight: "normal" }}>
                        The processing of personal data is managed over time by
                        specific responsibilities identified within the company
                        organization.
                      </div>
                    </li>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Principle of transparency
                      <div style={{ fontWeight: "normal" }}>
                        Personal data are collected and subsequently processed
                        according to the principles expressed by the Privacy
                        Policy adopted, indicated in this Privacy Policy. At the
                        time of any provision of data, information is provided
                        to the interested party, concise but complete, in
                        accordance with the provisions of the GDPR.
                      </div>
                    </li>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Principle of relevance collection
                      <div style={{ fontWeight: "normal" }}>
                        Personal data are processed lawfully and fairly; they
                        are registered for specific, explicit and legitimate
                        purposes; the purposes of the processing are relevant
                        and not excessive; are kept for the time necessary for
                        the purposes of collection.
                      </div>
                    </li>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Principle of usage purpose
                      <div style={{ fontWeight: "normal" }}>
                        The purposes of the processing of personal data are made
                        known to the interested parties at the time of
                        collection. Any new data processing, if unrelated to the
                        stated purposes, is activated upon new information to
                        the interested party and any request for consent, when
                        required by the GDPR.
                      </div>
                    </li>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Principle of verifiability
                      <div style={{ fontWeight: "normal" }}>
                        Personal data are accurate and updated over time. They
                        are also organized and stored in such a way that the
                        interested party is given the opportunity to know, if he
                        wishes, which data have been collected and recorded, as
                        well as to check their quality and request any
                        correction, integration, cancellation for violation of
                        the law or opposition to the processing and to exercise
                        all the other rights provided for by the GDPR at the
                        addresses indicated in the information present on the
                        website.
                      </div>
                    </li>
                    <li
                      style={{
                        fontWeight: "500",
                        marginTop: "8px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      Safety Principle
                      <div style={{ fontWeight: "normal" }}>
                        Personal data are protected by technical, IT,
                        organizational, logistical and procedural security
                        measures, against the risk of destruction or loss, even
                        accidental, and of unauthorized access or unauthorized
                        processing. These measures are periodically updated on
                        the basis of technical progress, the nature of the data
                        and the specific characteristics of the processing,
                        constantly monitored and verified over time.
                      </div>
                    </li>
                  </ol>
                </div>
                <div style={{ marginTop: "10px" }}>
                  Third parties who carry out support activities of any kind for
                  the provision of services by Sevat srl, in relation to which
                  they perform personal data processing operations, are
                  designated by the latter as Data Processors and are
                  contractually bound to compliance with the measures for the
                  security and confidentiality of processing. The identity of
                  these third parties is disclosed to users. With the consent of
                  the interested parties, if required by law, and in any case
                  subject to adequate information specifying the various
                  purposes, personal data may be disclosed to third parties,
                  public and private, unrelated to Sevat srl, who will treat
                  them as independent owners. of the treatment. Sevat srl is in
                  no way responsible for the processing of personal data carried
                  out by said third party data controllers.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Data Controller
                </div>
                <div>Sevat srl is the Data Controller.</div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Internal data processing manager
                </div>
                <div>
                  Gardino Pierfederico is the internal data processing manager.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Object of the treatment
                </div>
                <div>
                  Implicit personal data must be as follows:
                  <ul>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      identification (biographical data, email address, ...)
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      genetic data (of racial or ethnic origin, ...)
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      biometric data (physical, physiological and behavioral
                      characteristics that can be derived from existing and
                      proprietary documents)
                    </li>
                  </ul>
                </div>
                <div>
                  Any other data acquired from our given mandate refers to any
                  detail of our analysis.
                </div>
                <div style={{ marginTop: "5px" }}>
                  Particular data freely provided by you in the "Contact us"
                  form could be processed.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Navigation data
                </div>
                <div>
                  The computer systems and software procedures used to run the
                  website acquire, during their normal operation, some Personal
                  Data whose transmission is implicit in the use of Internet
                  communication protocols. This information is not collected to
                  be associated with identified interested parties, but which by
                  their very nature could, through processing and association
                  with data held by third parties, allow users to be identified.
                  This category of data includes the IP addresses or domain
                  names of the computers used by users who connect to the
                  website, the URI (Uniform Resource Identifier) ​​addresses of
                  the requested resources, the time of the request, the method
                  used to submit the request to the server, the size of the file
                  obtained in response, the numerical code indicating the status
                  of the response given by the server (successful, error, etc.)
                  and other parameters relating to the operating system and the
                  user's IT environment. These data are used for the sole
                  purpose of obtaining anonymous statistical information on the
                  use of the website and to check its correct functioning, to
                  identify anomalies and / or abuses, and are deleted
                  immediately after processing. The data could be used to
                  ascertain responsibility in the event of hypothetical computer
                  crimes against the website or third parties: except for this
                  possibility, the data on web contacts do not currently persist
                  for more than seven days.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Special categories of personal data
                </div>
                <div>
                  When using the "Contact us" section of the form, there may be
                  a provision of your Personal Data falling within the category
                  of the special categories of Personal Data referred to in art.
                  9 of the Regulation, verbatim the "[...] data suitable for
                  revealing racial or ethnic origin, political opinions,
                  religious or philosophical beliefs, or trade union membership,
                  as well as processing genetic data, biometric data intended to
                  uniquely identify a natural person, data relating to the
                  health or sexual life or sexual orientation of the person ".
                  We invite you to communicate such data only where strictly
                  necessary. Indeed, we remind you that in the face of the
                  transmission of special categories of Personal Data, but in
                  the absence of specific consent to process such data (an
                  eventuality that obviously allows you to send a curriculum
                  vitae), Sevat srl cannot be held responsible. in no way, nor
                  will it be able to receive disputes of any kind, since in this
                  case the processing will be allowed as it relates to data
                  manifestly made public by the interested party, in accordance
                  with art. 9 of the Regulations. However, we specify the
                  importance, as already indicated above - of expressing
                  explicit consent to the processing of special categories of
                  Personal Data, should you decide to share such information.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Data provided voluntarily by the interested party
                </div>
                <div>
                  When using the website, the processing of Personal Data of
                  third parties sent to Sevat srl may occur. With respect to
                  these hypotheses, you act as an independent data controller,
                  assuming all the obligations and responsibilities of the law.
                  In this sense, it confers on this point the widest indemnity
                  with respect to any dispute, claim, request for compensation
                  for damage from treatment, etc. that it should reach Sevat srl
                  from third parties whose Personal Data have been processed
                  through your use of the website functions in violation of the
                  applicable rules on the protection of personal data. In any
                  case, should it provide or otherwise process Personal Data of
                  third parties in the use of the website, it guarantees from
                  now - assuming all related responsibility - that this
                  particular hypothesis of treatment is based on an appropriate
                  legal basis pursuant to art . 6 of the Regulation which
                  legitimizes the processing of the information in question.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Data provided voluntarily by the interested party
                </div>
                <div>
                  The data provided is necessary to carry out our planned
                  activities, to ensure the security of the website and to carry
                  out any administrative tasks.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Legal basis and mandatory or optional treatment processing
                </div>
                <div>
                  The legal basis for the processing of Personal Data for the
                  purposes referred to in section 3 (a-b) is art. 6 (1) (b) of
                  the Regulations as the processing is necessary for the
                  provision of the Services or for the response of requests from
                  the interested party. The provision of Personal Data for these
                  purposes is optional but failure to provide it would make it
                  impossible to activate the Services provided by the website.
                  The purpose referred to in section 3c represents a legitimate
                  processing of Personal Data pursuant to art. 6 (1) (c) of the
                  Regulation. Once the Personal Data has been provided, the
                  processing is indeed necessary to fulfill a legal obligation
                  to which Sevat srl is subject. For the treatments carried out
                  for the purpose of sending direct advertising material or
                  direct sales or for carrying out market research or commercial
                  communications in relation to products or services of Sevat
                  srl similar to those requested, Sevat srl may use, without
                  your consent, the e-mail and paper mail addresses pursuant to
                  and within the limits permitted by the provision of the
                  Guarantor Authority for the protection of personal data of 19
                  June 2008; the legal basis for the processing of your data for
                  this purpose is art. 6.1.f) of the Regulations. In any case,
                  pursuant to art. 21 of the Regulation, has the possibility to
                  oppose this treatment at any time, initially or on the
                  occasion of subsequent communications, easily and free of
                  charge, also by writing to the Data Controller, as well as to
                  obtain immediate feedback confirming the interruption of this
                  treatment (art . 15 of the Regulation).
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Recipients of personal data
                </div>
                <div>
                  Personal Data may be shared, for the purposes described above,
                  with:
                  <ul>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      subjects who typically act as data processors pursuant to
                      art. 28 of the Regulation, namely: i) people, companies or
                      professional firms that provide assistance and consultancy
                      to Sevat srl in accounting, administrative, legal, tax,
                      financial and credit recovery matters relating to the
                      provision of the Services; ii) subjects with whom it is
                      necessary to interact for the provision of the Services
                      (for example hosting providers); iii) or subjects
                      delegated to carry out technical maintenance activities
                      (including maintenance of network equipment and electronic
                      communication networks); (collectively "Recipients"); the
                      list of data processors who process data can be requested
                      from the Data Controller.
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      subjects, bodies or authorities, independent data
                      controllers, to whom it is mandatory to communicate the
                      Personal Data by virtue of the provisions of the law or
                      orders of the authorities;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      persons authorized by Sevat srl to process Personal Data
                      pursuant to art. 29 of the Regulations necessary to carry
                      out activities strictly related to the provision of the
                      Services, which are committed to confidentiality or have
                      an adequate legal obligation of confidentiality (eg
                      employees of Sevat srl)
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Methods of storage and processing
                </div>
                <div>
                  The processing of personal data will be carried out
                  automatically and / or manually, pursuant to Article 32 on the
                  Security Measures Regulation, by authorized persons, pursuant
                  to Article 29 of the Regulation, who will act under the
                  authority of the controller and / or the data processor. Only
                  authorized persons can process your personal data.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Communication and distribution areas
                </div>
                <div>
                  The personal data provided may be disclosed to third parties
                  to fulfill legal obligations, in execution of orders from
                  public authorities entitled to do so or to assert or defend a
                  right in court. If necessary in relation to particular
                  services or products requested, personal data may be disclosed
                  to third parties who perform, as independent data controllers,
                  functions strictly connected and instrumental to the provision
                  of services or supply of products. Without communication,
                  these services and products could not be provided. Personal
                  data will not be disclosed, unless the requested service
                  requires it.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Transfer of personal data
                </div>
                <div>
                  Your personal data is stored on local servers at our legal
                  address; it will never be transferred to any of the Member
                  States of the European Union or to third countries outside the
                  European Union.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Special categories of personal data
                </div>
                <div>
                  Pursuant to articles 9 and 10 of the regulation, it is
                  possible to provide data labeled as "special categories of
                  personal data", ie data that reveal "racial or ethnic origin,
                  political opinions, religious and philosophical beliefs or
                  trade union membership, as well as biometric data intended for
                  unambiguously identify the natural person, data relating to
                  personal health, sexual life or sexual orientation ".
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Data retention
                </div>
                <div>
                  The Personal Data processed for the aforementioned purposes
                  will be kept for the time strictly necessary to achieve those
                  same purposes in compliance with the principles of
                  minimization and limitation of conservation pursuant to art.
                  5.1.e) of the Regulations. In any case, the Data Controller
                  will process the Personal Data for the time necessary for
                  contractual and legal obligations.
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Your rights
                </div>
                <div>
                  Pursuant to articles 15 and 22 of the regulation, at any time
                  it is possible to exercise the right to:
                  <ul>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Request the existence of your personal data;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Obtain information on the purpose of the processing of
                      personal data, the categories of personal data, the
                      recipient or group of recipients to whom the personal data
                      have been or will be transferred and, where possible, the
                      storage duration;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Receive updates, changes and deletion of personal data;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Obtain limitations on the processing of your personal
                      data;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Request data portability, i.e. receive personal
                      information from a data controller, in an easy-to-read
                      structured format and transfer it to another controller
                      without hindrance;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Refuse to approve data processing at any time even in the
                      case of direct marketing;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Refuse to approve any automated decision-making process
                      relating to individuals, including profiling;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Ask the data controller for access to their personal data
                      and request changes, deletions, limitations or their
                      refusal of processing, beyond data portability;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Revoke the approval at any time without compromising the
                      legality based on the consent prior to the revocation;
                    </li>
                    <li
                      style={{
                        marginTop: "5px",
                        marginLeft: "30px",
                        display: "list-item",
                        listStyle: "outside",
                      }}
                    >
                      Comply with the supervisory authority.
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  How to exercise your rights
                </div>
                <div>
                  You can exercise your rights by sending a registered letter
                  a.r. to Sevat srl at our legal address or through the
                  following certified e-mail address:{" "}
                  <a
                    className="aTagUnderline"
                    href="mailto:privacy@sevatgroup.com"
                  >
                    privacy@sevatgroup.com
                  </a>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "2px",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "rgb(49, 62, 91)",
                  }}
                >
                  Updates
                </div>
                <div>
                  Sevat srl srl reserves the right to modify or simply update
                  its content, in part or completely, also due to changes in the
                  applicable legislation. Sevat srl will inform you of these
                  changes as soon as they are introduced and they will be
                  binding as soon as they are published on the website. Sevat
                  srl srl therefore invites you to regularly visit this section
                  to become aware of the most recent and updated version of the
                  privacy policy in order to be always updated on the data
                  collected and on the use made of it by Sevat srl
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* main-footer */}
      <Footer content={content} />
      {/* main-footer end */}

      <button
        className={"scroll-top scroll-to-target " + open}
        data-target="html"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        style={{ right: "10px" }}
      >
        <span className="fa fa-arrow-up"></span>
      </button>
      {/* jequery plugins */}
      {/* main-js */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  width: state.windowDimension.width,
  language: state.layout.language,
});

export default connect(mapStateToProps)(TermsOfService);
