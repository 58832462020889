import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import RowMenuSidebar from "./RowMenuSidebar";
import SubMenuSidebar from "./SubMenuSidebar";

export const MenuSidebar = (props) => {
  const [isOpen1, setIsOpen1] = useState(false);

  const checkIfChildren = (children) => {
    if (children !== undefined && children.length !== 0 && children !== null) {
      return true;
    } else {
      return false;
    }
  };

  const styleMenuItem = {
    cursor: "pointer",
    borderLeft: "4px solid transparent",
    padding: "0px 22px",
  };

  return (
    <div>
      {/* level 1 */}
      <RowMenuSidebar
        menu={props.menu}
        isOpen={isOpen1}
        setIsOpen={setIsOpen1}
        styleMenuItem={styleMenuItem}
        extraStyle={{}}
      ></RowMenuSidebar>

      {/* level 2 */}
      {checkIfChildren(props.menu.children) ? (
        <Collapse isOpen={isOpen1}>
          {props.menu.children.map((obj, index) => {
            return (
              <SubMenuSidebar
                key={index}
                menu={obj}
                styleMenuItem={styleMenuItem}
              ></SubMenuSidebar>
            );
          })}
        </Collapse>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  layout: state.layout,
});

export default connect(mapStateToProps)(MenuSidebar);
