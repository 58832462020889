import * as AntDesign from "react-icons/ai";
import * as BootstrapIcon from "react-icons/bs";
import * as FontAwesome from "react-icons/fa";
import * as MaterialDesign from "react-icons/md";

export const iconsMapped = (strIcon, activeMenu) => {
  var styleIcon = {
    fontSize: 13,
    marginRight: 10,
    marginTop: "-4px",
  };
  if (activeMenu === true) {
    styleIcon = { ...styleIcon };
  }
  if (strIcon.startsWith("Ai")) {
    const IconComponent = AntDesign[strIcon];
    return <IconComponent style={styleIcon} className="underlineOnHover" />;
  } else if (strIcon.startsWith("Bs")) {
    const IconComponent = BootstrapIcon[strIcon];
    return <IconComponent style={styleIcon} className="underlineOnHover" />;
  } else if (strIcon.startsWith("Fa")) {
    const IconComponent = FontAwesome[strIcon];
    return <IconComponent style={styleIcon} className="underlineOnHover" />;
  } else if (strIcon.startsWith("Md")) {
    const IconComponent = MaterialDesign[strIcon];
    return <IconComponent style={styleIcon} className="underlineOnHover" />;
  }
};
