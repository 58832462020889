import React, { useEffect, useState, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "editorjs-header-with-alignment";
import Paragraph from "editorjs-paragraph-with-alignment";
import ImageTool from "@editorjs/image";
import AttachesTool from "@editorjs/attaches";
// import MermaidTool from "editorjs-mermaid";
import FontSize from "editorjs-inline-font-size-tool";
import AnyButton from "editorjs-button";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import Table from "@editorjs/table";
import NestedList from "@editorjs/nested-list";
import Checklist from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import TextField from "@mui/material/TextField";
import Alert from "editorjs-alert";
import Button from "@mui/material/Button";
import {
  Button as ReactButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ErrorAlert from "../../components/Modals/ErrorAlert";
import {
  creaNews,
  getNewsDetails,
  modificaNews,
  deleteSingoloFile,
} from "../../actions/news";
import { API_URL, MODIFICA_NEWS } from "../../actions/types";
import { connect, useDispatch } from "react-redux";
import { tokenConfig } from "../../utils/tokenConfig";
import { getBase64 } from "../../utils/getBase64";
import moment from "moment";
import InfoAlert from "../../components/Modals/InfoAlert";

const Editor = (props) => {
  const [editor, setEditor] = useState(null);
  const [create, setCreate] = useState(null);
  const [titolo, setTitolo] = useState(null);
  const [sottotitolo, setSottotitolo] = useState(null);
  const [open, setOpen] = useState(false);
  const [anteprima, setAnteprima] = useState("");
  const [deleteNamesList, setDeleteNamesList] = useState([]);
  const ejInstance = useRef(null);

  const id = props.location.pathname.split("/").at(-1);

  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch({
        type: MODIFICA_NEWS,
        payload: { id: -1, data: {} },
      });
    },
    []
  );
  const EDITTOR_HOLDER_ID = "editorjs";
  useEffect(() => {
    if (isNaN(parseFloat(id))) {
      setCreate(true);
      if (ejInstance.current === null) {
        initEditor({
          time: moment().format("YYYY-MM-DD"),
          blocks: [
            {
              type: "header",
              data: {
                text: "Crea una nuova news!",
                level: 1,
              },
            },
          ],
        });
      }
      return () => {
        ejInstance.current.destroy();
        ejInstance.current = null;
      };
    } else {
      props.getNewsDetails(id);
    }
  }, []);

  useEffect(() => {
    if (props.newsDetails !== null) {
      setAnteprima(props.newsDetails.anteprima);
      setTitolo(props.newsDetails.titolo);
      setSottotitolo(props.newsDetails.sottotitolo);
      if (ejInstance.current === null) {
        initEditor(props.newsDetails);
      }
      return () => {
        ejInstance.current.destroy();
        ejInstance.current = null;
      };
    }
  }, [props.newsDetails]);

  const initEditor = (data) => {
    const editorInit = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: data,
      onReady: () => {
        ejInstance.current = editorInit;
      },
      onChange: (api, event) => {
        if (event.type === "block-removed") {
          const blockToDelete =
            editorInit.configuration.data.blocks[event.detail.index];
          if (blockToDelete !== undefined) {
            if ("file" in blockToDelete.data) {
              const fileName = blockToDelete.data.file.url.split("/").at(-1);
              setDeleteNamesList([...deleteNamesList, fileName]);
            }
          }
        }
      },
      // readOnly: true,
      autofocus: true,
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            field: "file",
            endpoints: {
              byFile: `${API_URL}/news/files/`, // Your backend file uploader endpoint
              byUrl: `${API_URL}/news/files/`, // Your endpoint that provides uploading by Url
            },
            additionalRequestHeaders: {
              Authorization: tokenConfig().headers.Authorization,
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: `${API_URL}/news/files/`,
            // additionalRequestHeaders: object to use as {"X-CSRF-TOKEN": "adsfsadfasdfasd"}
            additionalRequestHeaders: {
              Authorization: tokenConfig().headers.Authorization,
            },
          },
        },
        // mermaid: MermaidTool,
        fontSize: FontSize,
        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
        },
        Marker: {
          class: Marker,
        },
        underline: Underline,
        table: {
          class: Table,
          inlineToolbar: true,
          // config: {
          //   rows: 2,
          //   cols: 3,
          // },
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
      },
    });
    setEditor(editorInit);
    return editorInit;
  };

  return (
    <div>
      <div
        style={{
          margin: "auto",
          maxWidth: "850px",
          display: "flex",
          justifyContent: "end",
          padding: "5px",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(true);
            // editor
            //   .save()
            //   .then((outputData) => {
            //     console.log("Article data: ", outputData);
            //   })
            //   .catch((error) => {
            //     ErrorAlert(String(error));
            //   });
          }}
        >
          Prosegui
        </Button>
      </div>
      <div
        style={{
          margin: "auto",
          maxWidth: "850px",
          overflowY: "scroll",
          height: "85vh",
          background: "#fff",
          // borderRadius: "8px",
          WebkitBoxShadow:
            "0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%)",
          boxShadow:
            "rgb(69 104 129 / 33%) 0px 24px 24px -18px, rgb(114 119 160 / 12%) 0px 945px 0px",
          padding: "70px 50px",
          fontSize: "16px",
          WebkitBoxSizing: "border-box",
          boxSizing: "border-box",
        }}
      >
        <div id={EDITTOR_HOLDER_ID}> </div>
      </div>
      <Modal isOpen={open}>
        <ModalHeader>Imposta Anteprima</ModalHeader>
        <ModalBody>
          <div style={{ padding: 10 }}>
            <div style={{ textAlignLast: "center" }}>
              {anteprima !== "" ? (
                <img
                  src={`data:image;base64,${anteprima}`}
                  style={{
                    maxHeight: "200px",
                    maxWidth: "300px",
                    cursor: "pointer",
                  }}
                  alt=""
                />
              ) : (
                <img
                  alt=""
                  style={{
                    height: "200px",
                    width: "300px",
                    backgroundColor: "dimgrey",
                    cursor: "pointer",
                  }}
                />
              )}
              {/* <div
              
                style={{
                  background: "red",
                  pointerEvents: "none",
                  maxHeight: "200px",
                  maxWidth: "446px",
                  height: "200px",
                  width: "446px",
                  opacity: "1",
                  lineHeight: "90px",
                  textAlign: "center",
                }}
              >
                Aggiungi Anteprima
              </div> */}
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onClick={(e) => {
                  e.target.value = "";
                }}
                onChange={(e) => {
                  getBase64(e.target.files[0]).then((value) => {
                    if (value.includes(",")) {
                      if (e.target.files[0].size <= 100000000) {
                        setAnteprima(value.split(",")[1]);
                      } else
                        InfoAlert(
                          "Il file inserito non può essere maggiore di 100MB"
                        );
                    } else {
                      InfoAlert("Il file inserito è vuoto");
                    }
                  });
                }}
                style={{
                  cursor: "pointer",
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              ></input>
            </div>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline legend": {
                  display: "none",
                },
              }}
              onChange={(e) => {
                setTitolo(e.target.value);
              }}
              style={{ width: "100%", paddingTop: 20 }}
              value={titolo}
              placeholder={"Titolo"}
            />
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline legend": {
                  display: "none",
                },
              }}
              onChange={(e) => {
                setSottotitolo(e.target.value);
              }}
              style={{ width: "100%", paddingTop: 20 }}
              value={sottotitolo}
              placeholder={"Sottotitolo"}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            onClick={() => {
              editor
                .save()
                .then((outputData) => {
                  if (create === true) {
                    props.creaNews({
                      time: moment().format("YYYY-MM-DD"),
                      blocks: outputData.blocks,
                      titolo: titolo,
                      sottotitolo: sottotitolo,
                      anteprima: anteprima,
                    });
                    props.history.push("/dashboard/homepage");
                  } else {
                    props.modificaNews(id, {
                      blocks: outputData.blocks,
                      titolo: titolo,
                      sottotitolo: sottotitolo,
                      anteprima: anteprima,
                    });
                    props.history.push("/dashboard/homepage");
                  }
                })
                .catch((error) => {
                  ErrorAlert(String(error));
                });
              if (deleteNamesList.length > 0) {
                for (const fileName of deleteNamesList) {
                  props.deleteSingoloFile({
                    file: fileName,
                  });
                }
              }
            }}
          >
            {create === true ? "Crea" : "Modifica"}
          </Button>
          <ReactButton
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Indietro
          </ReactButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({ newsDetails: state.news.newsDetails });

export default connect(mapStateToProps, {
  creaNews,
  getNewsDetails,
  modificaNews,
  deleteSingoloFile,
})(Editor);
